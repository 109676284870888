<template>
  <v-app style="background: #ffffff; ">
    <v-main>
      <div
        v-if="resolutionScreen >= 500"
        class="d-flex justify-center"
        style="height: 100%; background: linear-gradient(to bottom, #4CACBC, #6CC4A1, #B2EBF2, #8dbff2); overflow-x: hidden;"
      >
        <v-layout row wrap align-center justify-center>
          <v-flex class="d-flex justify-center">
            <v-card
              width="550"
              outlined
              elevation="24"
              color="white"
              class="rounded-xl"
              height="auto"
            >
              <v-card-text class="pa-12 mt-6">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                  fill-height
                  class="ma-0"
                >
                  <v-flex lg12 style="background: white" class="" v-if="status === 'This request has been handled.'">
                    <v-card-text class="text-center">
                      <h2 class="text-center">คำขอสิทธิ์ดาวน์โหลด</h2>
                      <h3 class="text-left ml-6 mt-6">
                        คุณ {{ request_name }} ขอสิทธิ์ดาวโหลด รายละเอียดดังนี้
                      </h3>
                      <h3 class="text-left ml-6">ชื่อ : {{ data_name }}</h3>
                      <h3 class="text-left ml-6">ประเภท : {{ data_type }}</h3>
                      <h3 class="text-left ml-6">
                        ยื่นขอสิทธิ์ดาวน์โหลด วันที่ {{ time_rerquest }}
                      </h3>
                      <h2
                        class="text py-4 mt2-n1 text-center mb-2"
                        style="font-size:20px; color: red"
                      >
                        คุณได้ทำรายการอนุมัติเสร็จสิ้นแล้ว
                      </h2>

                    </v-card-text>
                  </v-flex>
                  <v-flex lg12 style="background: white" class="" v-else>
                    <v-card-text class="text-center">
                      <h2 class="text-center">คำขอสิทธิ์ดาวน์โหลด</h2>
                      <h3 class="text-left ml-6 mt-6">
                        คุณ {{ request_name }} ขอสิทธิ์ดาวโหลด รายละเอียดดังนี้
                      </h3>
                      <h3 class="text-left ml-6">ชื่อ : {{ data_name }}</h3>
                      <h3 class="text-left ml-6">ประเภท : {{ data_type }}</h3>
                      <h3 class="text-left ml-6">
                        ยื่นขอสิทธิ์ดาวน์โหลด วันที่ {{ time_rerquest }}
                      </h3>
                      <h2
                        class="text py-4 mt2-n1 text-center mb-2"
                        style="font-size:20px;"
                      >
                        คุณต้องการอนุมัติคำขอดาวน์โหลดเอกสารหรือไม่ ?
                      </h2>

                      <v-layout row wrap justify-center class="mt-n5">
                        <v-flex lg11 xs11>
                          <v-switch
                            v-model="switch1"
                            inset
                            :label="$t('requestdownload.settime_download')"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center>
                        <v-flex lg5 xs11 v-if="switch1 === true">
                          <v-dialog
                            persistent
                            v-model="modal"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                v-model="picker"
                                :label="$t('sharefile.setdate')"
                                readonly
                                prepend-icon="mdi-calendar"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="picker"
                              :min="datenow"
                              @input="modal = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn dark color="error" @click="modal = false">
                                {{ $t("sharefile.closedatepiker") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                        <v-flex lg3 xs6 v-if="switch1 === true">
                          <v-select
                            class="pl-1"
                            :label="$t('sharefile.sethours')"
                            outlined
                            dense
                            :items="hours"
                            v-model="hour"
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg3 xs5 v-if="switch1 === true">
                          <v-select
                            class="pl-1"
                            :label="$t('sharefile.setminute')"
                            outlined
                            dense
                            :items="minutes"
                            v-model="minute"
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                      <v-btn
                        class="px-3 mt-2"
                        x-large
                        rounded
                        color="red"
                        @click="fn_notappove_request"
                        width="200"
                        outlined
                      >
                        {{ $t("requestdownload.no_appove_request") }}
                      </v-btn>
                      <v-btn
                        class="px-3 mt-2 ml-2 white--text"
                        x-large
                        rounded
                        color="#005799"
                        @click="fn_appove_request"
                        width="200"
                      >
                        {{ $t("requestdownload.appove_request") }}
                      </v-btn>
                    </v-card-text>
                  </v-flex>
              
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>

      <!-- ขนาดหน้าจอโทรศัพท์ -->
      <div
        v-else
        class="d-flex justify-center"
        style="height: 100%; background: linear-gradient(to bottom, #4CACBC, #6CC4A1, #B2EBF2, #8dbff2); overflow-x: hidden;"
      >
        <v-layout row wrap align-center justify-center>
          <v-flex class="d-flex justify-center">
            <v-card
              width="80%"
              outlined
              elevation="24"
              color="white"
              class="rounded-xl"
              height="600"
            >
              <v-card-text class="pa-12 mt-12">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                  fill-height
                  class="ma-0"
                >
                <v-flex lg12 style="background: white" class="" v-if="status === 'This request has been handled.'">
                    <v-card-text class="text-center">
                      <h2 class="text-center">คำขอสิทธิ์ดาวน์โหลด</h2>
                      <h3 class="text-left ml-6 mt-6">
                        คุณ {{ request_name }} ขอสิทธิ์ดาวโหลด รายละเอียดดังนี้
                      </h3>
                      <h3 class="text-left ml-6">ชื่อ : {{ data_name }}</h3>
                      <h3 class="text-left ml-6">ประเภท : {{ data_type }}</h3>
                      <h3 class="text-left ml-6">
                        ยื่นขอสิทธิ์ดาวน์โหลด วันที่ {{ time_rerquest }}
                      </h3>
                      <h2
                        class="text py-4 mt2-n1 text-center mb-2"
                        style="font-size:20px; color: red"
                      >
                        คุณได้ทำรายการอนุมัติเสร็จสิ้นแล้ว
                      </h2>

                    </v-card-text>
                  </v-flex>
                  <v-flex lg12 style="background: white" class="" v-else>
                    <v-card-text class="text-center">
                      <h2 class="text-center">คำขอสิทธิ์ดาวน์โหลด</h2>
                      <h3 class="text-left ml-6 mt-6">
                        คุณ {{ request_name }} ขอสิทธิ์ดาวโหลด รายละเอียดดังนี้
                      </h3>
                      <h3 class="text-left ml-6">ชื่อ : {{ data_name }}</h3>
                      <h3 class="text-left ml-6">ประเภท : {{ data_type }}</h3>
                      <h3 class="text-left ml-6">
                        ยื่นขอสิทธิ์ดาวน์โหลด วันที่ {{ time_rerquest }}
                      </h3>
                      <h2
                        class="text py-4 mt2-n1 text-center mb-2"
                        style="font-size:20px;"
                      >
                        คุณต้องการอนุมัติคำขอดาวน์โหลดเอกสารหรือไม่ ?
                      </h2>

                      <v-layout row wrap justify-center class="mt-n5">
                        <v-flex lg11 xs11>
                          <v-switch
                            v-model="switch1"
                            inset
                            :label="$t('requestdownload.settime_download')"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center>
                        <v-flex lg5 xs11 v-if="switch1 === true">
                          <v-dialog
                            persistent
                            v-model="modal"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                v-model="picker"
                                :label="$t('sharefile.setdate')"
                                readonly
                                prepend-icon="mdi-calendar"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="picker"
                              :min="datenow"
                              @input="modal = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn dark color="error" @click="modal = false">
                                {{ $t("sharefile.closedatepiker") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                        <v-flex lg3 xs6 v-if="switch1 === true">
                          <v-select
                            class="pl-1"
                            :label="$t('sharefile.sethours')"
                            outlined
                            dense
                            :items="hours"
                            v-model="hour"
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg3 xs5 v-if="switch1 === true">
                          <v-select
                            class="pl-1"
                            :label="$t('sharefile.setminute')"
                            outlined
                            dense
                            :items="minutes"
                            v-model="minute"
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                      <v-btn
                        class="px-3 mt-2"
                        x-large
                        rounded
                        color="red"
                        @click="fn_notappove_request"
                        width="200"
                        outlined
                      >
                        {{ $t("requestdownload.no_appove_request") }}
                      </v-btn>
                      <v-btn
                        class="px-3 mt-2 ml-2 white--text"
                        x-large
                        rounded
                        color="#005799"
                        @click="fn_appove_request"
                        width="200"
                      >
                        {{ $t("requestdownload.appove_request") }}
                      </v-btn>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      status:"",
      datenow: "",
      modal: false,
      time_download: "",
      switch1: false,
      fail_: false,
      errormessage: "",
      request_name: "",
      data_name: "",
      data_type: "",
      time_rerquest: "",
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      picker: "",
      minute:"",
      hour:""
    };
  },
  created() {},
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  computed: {
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    // reset_login() {
    // this.$store
    //   .dispatch("authorize_expired")
    //   .then(
    //     // this.login()
    //   )
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // },
    async get_detail_request() {
      console.log("id", this.$route.query.id);
      let auth = await gbfGenerate.generateToken();
      let payload = {
        request_download_id: this.$route.query.id,
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_detail/rerquest_downloads",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            console.log("response", response.data.result);
            this.status = response.data.result.Message;
            this.request_name = response.data.result.requesters_name;
            this.data_name = response.data.result.data_name;
            this.data_type = response.data.result.data_type;
            this.time_rerquest = this.formatdatetime(
              response.data.result.time_rerquest
            );
          }
        });
    },
    formatdatetime(_datetime) {
      let dateyear =
        _datetime.split("")[0] +
        _datetime.split("")[1] +
        _datetime.split("")[2] +
        _datetime.split("")[3];
      let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
      let dateday = _datetime.split("")[6] + _datetime.split("")[7];
      let hour = _datetime.split("")[8] + _datetime.split("")[9];
      let minute = _datetime.split("")[10] + _datetime.split("")[11];
      let second = _datetime.split("")[12] + _datetime.split("")[13];

      return (
        dateday +
        "/" +
        datemonth +
        "/" +
        dateyear +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    async fn_appove_request() {
      let auth = await gbfGenerate.generateToken();
      if (this.switch1 == true) {
        if (this.picker === "" || this.hour === "" || this.minute === "") {
          Toast.fire({
            icon: "warning",
            title: "กรุณาเลือกวันที่และเวลาที่ต้องการ",
          });
        } else {
          console.log("this.picker", this.picker);
          console.log("this.hour", this.hour);
          console.log("this.minute", this.minute);

          let datesplit = this.picker.split("-");
          let date =
            datesplit[0] +
            datesplit[1] +
            datesplit[2] +
            this.hour +
            this.minute +
            "00";
          this.time_download = date;

          let payload = {
            request_download_id: this.$route.query.id,
            status_request: "Y",
            status_downloads: "Y",
            time_download: this.time_download,
          };
          console.log("payload", payload);
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                "/api/approve/rerquest_downloads",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "OK") {
                this.get_detail_request();
                Toast.fire({
                  icon: "success",
                  title: "อนุมัติการดาวน์โหลดสำเร็จ",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: "อนุมัติการดาวน์โหลดไม่สำเร็จ",
                });
              }
            });
        }
      } else {
        let payload = {
          request_download_id: this.$route.query.id,
          status_request: "Y",
          status_downloads: "N",
          time_download: "",
        };
        console.log("payload", payload);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/approve/rerquest_downloads",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "อนุมัติการดาวน์โหลดสำเร็จ",
              });
            } else {
              Toast.fire({
                icon: "error",
                title: "อนุมัติการดาวน์โหลดไม่สำเร็จ",
              });
            }
          });
      }
    },
    async fn_notappove_request() {
      let auth = await gbfGenerate.generateToken();
      let payload = {
        request_download_id: this.$route.query.id,
        status_request: "N",
        status_downloads: "",
        time_download: "",
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/approve/rerquest_downloads",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            console.log("response", response.data.result);
            Toast.fire({
              icon: "success",
              title: "ทำรายการเสร็จสิ้น",
            });
          }else{
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    this.get_detail_request();
  },
};
</script>
